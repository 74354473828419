import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'tab1',
    loadChildren: () => import('./tab1/tab1.module').then(m => m.Tab1PageModule)
  },
  {
    path: 'tab2',
    loadChildren: () => import('./tab2/tab2.module').then(m => m.Tab2PageModule)
  },
  {
    path: 'tab3',
    loadChildren: () => import('./tab3/tab3.module').then(m => m.Tab3PageModule)
  },
  {
    path: 'tab4',
    loadChildren: () => import('./tab4/tab4.module').then(m => m.Tab4PageModule),
  },
  {
    path: 'tab5',
    loadChildren: () => import('./tab5/tab5.module').then(m => m.Tab5PageModule),
  },
  {
    path: 'tab5/:idcliente/:cliente',
    loadChildren: () => import('./tab5/tab5.module').then(m => m.Tab5PageModule),
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'prodottiedit/:iditem',
    loadChildren: () => import('./prodottiedit/prodottiedit.module').then( m => m.ProdottieditPageModule)
  },
  {
    path: '',
    //loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'followeredit/:idcliente',
    loadChildren: () => import('./followeredit/followeredit.module').then( m => m.FollowereditPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  }, 
  {
    path: 'login/:token',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },   
  {
    path: 'ordini',
    loadChildren: () => import('./ordini/ordini.module').then( m => m.OrdiniPageModule)
  },
  {
    path: 'ordini/:idcliente/:cliente',
    loadChildren: () => import('./ordini/ordini.module').then( m => m.OrdiniPageModule)
  },   
  {
    path: 'tracciamenti',
    loadChildren: () => import('./tracciamenti/tracciamenti.module').then( m => m.TracciamentiPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'newsedit/:idnews',
    loadChildren: () => import('./newsedit/newsedit.module').then( m => m.NewseditPageModule)
  },
  {
    path: 'condividi',
    loadChildren: () => import('./condividi/condividi.module').then( m => m.CondividiPageModule)
  },
  {
    path: 'categoriaedit/:idcat',
    loadChildren: () => import('./categoriaedit/categoriaedit.module').then( m => m.CategoriaeditPageModule)
  },
  {
    path: 'prenotazioniedit/:iditem',
    loadChildren: () => import('./prenotazioniedit/prenotazioniedit.module').then( m => m.PrenotazionieditPageModule)
  },
  {
    path: 'specificheedit',
    loadChildren: () => import('./specificheedit/specificheedit.module').then( m => m.SpecificheeditPageModule)
  },
  {
    path: 'specificheedit/:iditem/:idspec',
    loadChildren: () => import('./specificheedit/specificheedit.module').then( m => m.SpecificheeditPageModule)
  },
  {
    path: 'specifichevaloriedit',
    loadChildren: () => import('./specifichevaloriedit/specifichevaloriedit.module').then( m => m.SpecifichevalorieditPageModule)
  },
  {
    path: 'specifichevaloriedit/:iditem/:idspec/:idspecdetail',
    loadChildren: () => import('./specifichevaloriedit/specifichevaloriedit.module').then( m => m.SpecifichevalorieditPageModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./calendar/calendar.module').then( m => m.CalendarPageModule)
  },   
  {
    path: 'specificheritiroconsegna/:type',
    loadChildren: () => import('./specificheritiroconsegna/specificheritiroconsegna.module').then( m => m.SpecificheritiroconsegnaPageModule)
  },
  {
    path: 'prenotazioni',
    loadChildren: () => import('./prenotazioni/prenotazioni.module').then( m => m.PrenotazioniPageModule)
  },




];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
